import * as React from "react";
import IndexLayout from "../layouts";
import { navigate } from "gatsby";
import { useEffect } from "react";

const OurStoryPage = () => {
  useEffect(() => {
    navigate("/about-us");
  }, []);
  return <IndexLayout></IndexLayout>;
};

export default OurStoryPage;
